import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Form from './views/Vistoria';

ReactDOM.render(
	<BrowserRouter>
		<Switch>
			<Route path="/:id" render={props => <Form {...props} />} />
		</Switch>
	</BrowserRouter>,
	document.getElementById('root')
);
 