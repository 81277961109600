import React from "react";
import { api_vistoria } from '../services/apis';
import { Input, Modal, ModalBody, ModalFooter, Button } from "reactstrap";

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imovelData: '',
            perguntasData: '',
            perguntasRespostas: null,
            ft: [],
            ftHost: [],
            ftFile: [],
            respondidas: [],
            vistoria: '',
            done: false,
            selection: false,
            comodos: false,
            perguntas: false,
            allComodos: false,
            final: false,
            comodosList: [],
            selectedComodo: -1,
            selectedPergunta: '',
            descricao: '',
            respostaSelect: '',
            finals: [],
            comodosHouse: [],
            comodosAsk: [],
            aviso: false,
            avisomsg: '',
            askIndex: '',
            imobiliaria: '',
            special: false,
            specialName: '',
            outros: [],
            edit: false,
            idDados_vistoria: 0,
            indexoutros: 0,
            indel: false,
            deleteds: [],
            openModal: false,
            showCaracteristicas: false,
            nomecaract: '',
            timeoutcontrol: null,
            caracList: [],
            tiposCaract: [],
            addedcaract: [],
            hostcaract: [],
            seeSelectType: false,
            idoftype: -1,
            paintmaker: false,
            paintedLines: false,
            basecolorstroke: '#4677ff',
            caractsModal: false,
            avisoFT: false,
            indexft: 0,
            typecaracs: 1,

            medidores: false,
            medidas: [],
            medidasFeitas: [],
            indexmedidor: -1,
            avalmedidor: false,
            medidorligado: 1,
            medidorcode: '',
            medidorleitura: '',
            seeNewComodos: false,
            areacomodo: false,
            es: false,
            area: '',
            areacomodocampo: '',
            medidorImages: [],
            medidorFiles: [],
            avisoME: false,
            medidorImagesHost: [],
            ismedHost: false,

            detalhes: false,
            mobi: 1,
            faxi: 1,
            online: false,

            sended: true,
            estadoespecial: -1,
            finalModal: false,
            inClone: false,
            obsVistoria: false,
            observacao_vis: '',
            savedObs: false,
            
            seaCarac: '',
            newAmbienteNome: ''

        }

        this.maxW = window.innerWidth;
        this.maxH = window.innerHeight;

        this.canvas = null;
        this.context = null;

        this.indeximg = -1;

        this.times = false;
        this.xline = 0;
        this.yline = 0;
        this.pxline = 0;
        this.pyline = 0;

        this.scribbles = [];

        this.pre = [];
        this.nex = [];

        this.xcanvas = 0;
        this.ycanvas = 0;

        this.canvasimg = 0;
    }

    /**
     * @description Função executada apos pagina ser carregada
     */
    componentWillMount() {
        let self = this;

        this.setState({ online: window.navigator.onLine })

        window.addEventListener('online', () => {
            setTimeout(() => {
                self.setState({ aviso: true, avisomsg: "Internet reestabelecida, atualizando informações", online: true })
                self.fetchFormRequest()
            }, 1000);
        })

        window.addEventListener('offline', () => {
            self.setState({ online: false })
        })

        let h = window.location.pathname.split('/')

        if (h[h.length - 1] == "recusa") {
            this.recusarVistoria();
            this.setState({ done: true });
            return
        }

        if (h[h.length - 1] == "aceita") {
            this.aceitarVistoria2();
            this.fetchFormRequest();
            return
        }

        this.indexedDBinterface();
        this.fetchFormRequest();
    }

    /**
     * @description Busca todos os dados de uma vistoria como perguntas, comodos, resposta, imovel e etc ...
     */
    fetchFormRequest = async () => {
        let re = await api_vistoria(`/formulario/${this.props.match.params.id}`);
        if (re['status'] == 400) {
            this.setState({ done: "error" });
            return;
        }

        re = re.data;

        if (re.obs.length > 0){
            this.setState({
                observacao_vis: re.obs[0].obs,
                savedObs: true
            })
        }

        for (let i = 0; i < re['comodos'].length; i++) {
            re['comodos'][i]['indexqt'] = 0
        }

        let list = [];
        for (let i of re['comodosList']) {
            let nome, cindex;

            re['comodos'].filter((e, index) => {
                if (e.id === i.comodo_id) {
                    nome = e.nome
                    cindex = index
                }
            })

            nome = `${nome} ${re['comodos'][cindex].indexqt + 1}`;

            re['comodos'][cindex].indexqt += 1

            list.push({
                "id": i.id,
                "comodo": i.comodo_id,
                "nome": i.nome || nome,
                'area': i.area,
                "perguntas": re['perguntas'].filter(e => i.comodo_id === e.comodo_id)
            });
        }

        let respondidas = []
        for (let i of re['respondidas']) {
            let id = `${i.comodos_id}`;
            let j = {};
            j[id] = i.perguntas_id;
            respondidas.push(j)
        }

        let ids = []

        for (let i of re['medidasFeitas']) {
            ids.push(i.medidor_id)
        }

        let idb = indexedDB.open("coleta", 3)

        idb.onsuccess = async (ax) => {
            const db = ax.target.result;

            let trans = db.transaction(['perguntasOffline', 'outros'], 'readwrite')

            trans.objectStore('perguntasOffline').clear()

            for (let i of re['todoPerguntas']) {
                trans.objectStore('perguntasOffline').put(i)
            }

            let x = trans.objectStore('outros').getAll()

            x.onsuccess = async (l) => {
                let dados = l.target.result.filter(p => p.id.toString().includes('*'))

                for (let i of dados) {
                    trans.objectStore('perguntasOffline').add(i)
                }
                db.close()
            }
        }

        this.setState({
            imovelData: re['imovel'].data[0],
            comodosList: re['comodos'],
            vistoria: re['vistoria'],
            faxi: re['vistoria'].faxinado || 0,
            mobi: re['vistoria'].mobiliado || 0,
            perguntasData: re['perguntas'],
            perguntasRespostas: re['perguntasRespostas'],
            comodosHouse: list,
            respondidas: respondidas,
            imobiliaria: re['imobiliaria'],
            outros: re['outros'],
            tiposCaract: re['caracteristicas'],
            caracList: re['allcaracteristicas'],
            medidas: re['medidas'],
            medidasFeitas: ids,
            area: re['vistoria'].area
        });

        if (re['vistoria'].status === "Execução") {
            this.setState({ selection: true, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: false, es: false, seeNewComodos: false, medidores: false, avalmedidor: false, })

        }
    }

    /**
     * @description Recusa uma vistoria
     */
    recusarVistoria = async () => await api_vistoria(`/formulario/${this.props.match.params.id}/recusar`)
    /**
     * @description Inicia uma vistoria
     */
    aceitarVistoria = async () => await api_vistoria(`/formulario/${this.props.match.params.id}/aceitar`)
    /**
     * @description Aceita uma vistoria
     */
    aceitarVistoria2 = async () => await api_vistoria(`/formulario/${this.props.match.params.id}/aceitar2`)


    indexedDBinterface = async () => {
        let indexDB = window.indexedDB.open("coleta", 3);

        let self = this;

        indexDB.onsuccess = (e) => {
            self.uuidDB = e.target.result;
            e.target.result.transaction(['dadosVistoria'], 'readwrite').objectStore('dadosVistoria').put({ id: 1, hash: self.props.match.params.id })
            let x = e.target.result.transaction(['outros'], 'readwrite').objectStore('outros').getAll()

            x.onsuccess = (u) => {
                let dados = u.target.result.filter(p => p.id.toString().includes('*'))
                self.setState({ outros: self.state.outros.concat(dados) })
            }

            e.target.result.close()
        }

        indexDB.onupgradeneeded = (e) => {
            let db = e.target.result

            db.createObjectStore("perguntasOffline", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("perguntasUpdates", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("perguntasUpdatesImagem", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("perguntas", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("perguntasImagem", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("dadosVistoria", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("metragemComodo", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("metragemExterna", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("medidores", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("medidoresImagem", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("detalhes", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("outros", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("outrosImagem", { keyPath: 'id', autoIncrement: true })
            db.createObjectStore("comodos", { keyPath: 'id', autoIncrement: true })
        }
    }

    /**
     * @description Formata uma data para DD/MM/AAAA HH:MM:SS
     */
    formatDate = (dateInput) => {
        if (dateInput == undefined) return dateInput;
        dateInput = dateInput.toString();
        try {

            const [dataInput, horaInput] = dateInput.split(' ');
            const [ano, mes, dia] = dataInput.split('-');
            const [hora, minuto, segundo] = horaInput.split(':');
            return `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
        }
        catch{
            return dateInput;
        }
    }

    /**
     * @description Motra/oculta um aviso 
     */
    toggleAviso = async (msg = null) => {
        const { aviso } = this.state;
        this.setState({ aviso: !aviso, avisomsg: msg })
    }

    /**
     * @description Aceita uma vistoria e traz os dados dela
     */
    accpetView = async () => {
        try {
            await this.aceitarVistoria();
            await this.fetchFormRequest();
            this.seeInit();
            window.location.reload();

        } catch (err) {
            console.log(err);
        }
    }

    /**
     * @description Recusa uma vistoria e traz uma mensagem
     */
    refuseView = async () => {
        try {
            this.setState({ done: true });
            await this.recusarVistoria();
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * @description Abre a vistoria
     */
    seeInit = async () => this.setState({ selection: true, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: false, es: false, seeNewComodos: false, medidores: false, avalmedidor: false });

    /**
     * @description Finaliza uma vistoria
     */
    finalVistoria = async () => {
        await api_vistoria.post(`/formulario/${this.props.match.params.id}/final`);

        let idb = indexedDB.open("coleta", 3);

        idb.onsuccess = (c) => {
            let db = c.target.result;

            let trans = db.transaction(
                [
                    "perguntasOffline",
                    "perguntasUpdates",
                    "perguntasUpdatesImagem",
                    "perguntas",
                    "perguntasImagem",
                    "dadosVistoria",
                    "metragemComodo",
                    "metragemExterna",
                    "medidores",
                    "medidoresImagem",
                    "detalhes",
                    "outros",
                    "outrosImagem",
                    "comodos",
                ],
                "readwrite"
            );

            trans.objectStore("perguntasOffline").clear()
            trans.objectStore("perguntasUpdates").clear()
            trans.objectStore("perguntasUpdatesImagem").clear()
            trans.objectStore("perguntas").clear()
            trans.objectStore("perguntasImagem").clear()
            trans.objectStore("dadosVistoria").clear()
            trans.objectStore("metragemComodo").clear()
            trans.objectStore("metragemExterna").clear()
            trans.objectStore("medidores").clear()
            trans.objectStore("medidoresImagem").clear()
            trans.objectStore("detalhes").clear()
            trans.objectStore("outros").clear()
            trans.objectStore("outrosImagem").clear()
            trans.objectStore("comodos").clear()
            // indexedDB.deleteDatabase('coleta')

            db.close()
        }

        let r = await window.navigator.serviceWorker.getRegistration();

        if (r){   
            r.unregister()
        }
        this.setState({ done: true, final: true, vistoria: [], comodosHouse: [], finalModal: false })
    }

    /**
     * @description Abre a lista de comodos
     */
    seeAllComodos = async () => this.setState({ selection: false, comodos: false, perguntas: false, allComodos: true, special: false, detalhes: false, es: false, seeNewComodos: false, avalmedidor: false, medidores: false });

    /**
     * @description Abre as perguntas de um comodo
     */
    seeComodos = async () => this.setState({ selection: false, comodos: true, perguntas: false, allComodos: false, special: false, detalhes: false, es: false, seeNewComodos: false, avalmedidor: false, medidores: false, areacomodo: false });

    /**
     * @description Seleciona um comodo e abre as perguntas de um comodo
     */
    setComodo = async (index) => {
        this.setState({ selectedComodo: index });
        this.seeComodos();
    }

    /**
     * @description Abre uma pergunta para ser respondida
     */
    seePerguntas = async (index, e, finish = false) => {
        const { comodosHouse, selectedComodo } = this.state;

        if (window.navigator.onLine) {
            let re = await api_vistoria.post(`/formulario/${this.props.match.params.id}/getData`, { "comodo_id": comodosHouse[selectedComodo].id, "pergunta_id": e.id });
            if (re.data != "0") {

                this.setState({
                    selection: false,
                    comodos: false,
                    perguntas: true,
                    spacial: false,
                    selectedPergunta: e,
                    descricao: re.data.visto[0].resposta_aberta,
                    ft: [],
                    ftFile: [],
                    askIndex: index,
                    specialName: null,
                    respostaSelect: re.data.visto[0].resposta_id,
                    edit: true,
                    idDados_vistoria: re.data.visto[0].id,
                    showCaracteristicas: false,
                    addedcaract: re.data.addedcaract,
                    nomecaract: '',
                    hostcaract: [],
                    ftHost: re.data.imgs,
                });

                return
            }
            else {
                this.setState({ selection: false, comodos: false, perguntas: true, spacial: false, detalhes: false, selectedPergunta: e, descricao: '', ft: [], ftFile: [], askIndex: index, specialName: '', edit: false, showCaracteristicas: false, addedcaract: [], nomecaract: '', hostcaract: [], ftHost: [], respostaSelect: -1 });
            }
        }
        else if (finish) {
            let idb = indexedDB.open("coleta", 3)
            let self = this;

            idb.onsuccess = async (ax) => {
                const db = ax.target.result;

                let trans = db.transaction(['perguntasOffline', "perguntas"], 'readwrite')

                let allOff = trans.objectStore("perguntasOffline").getAll()
                let pert = trans.objectStore("perguntas").get(e.id)

                allOff.onsuccess = async (g) => {
                    let perg = g.target.result.filter(x => x.perguntas_id == e.id)

                    perg = perg[0];
                    if (perg) {
                        self.setState({
                            selection: false,
                            comodos: false,
                            perguntas: true,
                            spacial: false,
                            selectedPergunta: e,
                            descricao: perg.resposta_aberta || perg.descricao,
                            ft: [],
                            ftFile: [],
                            askIndex: index,
                            specialName: null,
                            respostaSelect: perg.resposta_id || perg.respostaSelect,
                            edit: true,
                            idDados_vistoria: perg.id,
                            showCaracteristicas: false,
                            addedcaract: perg.addedcaract,
                            nomecaract: '',
                            hostcaract: [],
                        });

                        db.close()
                    }
                    else {
                        pert.onsuccess = async (h) => {
                            if (h.target.result) {
                                self.setState({
                                    selection: false,
                                    comodos: false,
                                    perguntas: true,
                                    spacial: false,
                                    selectedPergunta: e,
                                    descricao: h.target.result.descricao,
                                    ft: [],
                                    ftFile: [],
                                    askIndex: index,
                                    specialName: null,
                                    respostaSelect: h.target.result.respostaSelect,
                                    edit: true,
                                    idDados_vistoria: "+" + e.id,
                                    showCaracteristicas: false,
                                    addedcaract: h.target.result.addedcaract,
                                    nomecaract: '',
                                    hostcaract: [],
                                });
                            }
                            else {
                                this.setState({ selection: false, comodos: false, perguntas: true, spacial: false, detalhes: false, selectedPergunta: e, descricao: '', ft: [], ftFile: [], askIndex: index, specialName: '', edit: false, showCaracteristicas: false, addedcaract: [], nomecaract: '', hostcaract: [], ftHost: [], respostaSelect: -1 });
                            }

                            db.close()
                        }
                    }
                }
            }
        }
        else {
            this.setState({ selection: false, comodos: false, perguntas: true, spacial: false, detalhes: false, selectedPergunta: e, descricao: '', ft: [], ftFile: [], askIndex: index, specialName: '', edit: false, showCaracteristicas: false, addedcaract: [], nomecaract: '', hostcaract: [], ftHost: [], respostaSelect: -1 });
        }

    }

    /**
     * @description Adiciona uma imagem a uma pergunta
     */
    appendImage = async (e, i) => {
        this.setState({ ft: this.state.ft.concat(e.target.result), ftFile: this.state.ftFile.concat(i) });
    }

    /**
     * @description Renderiza uma imagem e adiciona uma imagem 
     */
    addImage = async (e) => {
        for (let i of e.target.files) {
            if (!i.type.match('image.*')) {
                continue;
            }

            let rd = new FileReader();

            rd.onload = (j) => this.appendImage(j, i);

            rd.readAsDataURL(i)
        }
    }

    /**
     * @description Adiciona um comodo e entra nele 
     */
    changeComodo = async (e) => {
        const { comodosList, perguntasData, comodosHouse } = this.state;

        let comodo = comodosList[e];
        let indexcomodo = e

        if (window.navigator.onLine) {
            await api_vistoria.post(`/formulario/${this.props.match.params.id}/adicionar`, { "id": comodo.id });
            this.toggleAviso("Ambiente adicionado com sucesso");

            await this.fetchFormRequest();
            this.setComodo(this.state.comodosHouse.length - 1);
        }
        else {
            let list = { "comodo": comodo.id, "area": null };

            let nome;

            nome = `${comodo.nome} ${comodo.indexqt + 1}`;

            comodo.indexqt += 1

            list['nome'] = nome
            list['perguntas'] = perguntasData.filter(m => m.comodo_id === comodo.id)

            let idb = indexedDB.open("coleta", 3)
            let self = this;

            idb.onsuccess = async (k) => {
                let db = k.target.result;

                let trans = db.transaction(['comodos'], 'readwrite');

                let ax = trans.objectStore('comodos').put(list)

                ax.onsuccess = async (y) => {
                    trans.objectStore('comodos').delete(ax.result);
                    list['id'] = '-' + ax.result;

                    trans.objectStore('comodos').put(list)
                    comodosList[indexcomodo] = comodo
                    self.setState({ comodosHouse: comodosHouse.concat(list), comodosList: comodosList });

                    self.setComodo(self.state.comodosHouse.length - 1);
                    db.close()
                }
            }
        }
    }

    /**
     * @deprecated
     */
    asksComodo = () => {
        const { perguntasData, comodosList, selectedComodo } = this.state;

        return perguntasData.filter(j => {
            return j.comodo_id == comodosList[selectedComodo].id;
        });
    }

    /**
     * @description Lista as respostas de uma pergunta
     */
    respostasPergunta = () => {
        const { perguntasRespostas, selectedPergunta } = this.state;

        return perguntasRespostas.filter(j => {
            return j.pergunta_id === selectedPergunta.id;
        });
    }

    /**
     * @description Envia as imagens de uma pergunta
     */
    sendImages = async (id) => {
        const { ftFile } = this.state;

        for (let i of ftFile) {
            let form = new FormData();

            form.append('foto', i);
            form.append('id', id);

            api_vistoria.post(`/formulario/${this.props.match.params.id}/image`, form);

        }
    }

    /**
     * @description Envia uma pergunta respondida 
     */
    sendPergunta = async (t) => {
        console.log(this.state.sended);
        if (!this.state.sended) return;
        this.state.sended = false;

        this.btnNext.setAttribute("disabled", "disabled");
        this.btnPrevs.setAttribute("disabled", "disabled");

        document.getElementById('btndoidprev').disabled = true;
        document.getElementById("btndoidnext").disabled = true;

        this.setState({sended: false})
        const { descricao, respostaSelect, selectedPergunta, comodosHouse, selectedComodo, ftFile, respondidas, specialName, addedcaract, askIndex } = this.state;
        let self = this;
        try {
            if (t == -1) {
                let e = comodosHouse[selectedComodo].perguntas[askIndex - 1];
                if (e == undefined) {
                    this.seeComodos(selectedComodo);
                    
                    
                    setTimeout(() => {
                        self.setState({sended: true})
                        try {
                            document.getElementById('btndoidprev').disabled = false;
                            document.getElementById("btndoidnext").disabled = false;
                        } catch (error) {
                            
                        }

                    }, 1000);

                    return
                }

                this.seePerguntas(askIndex - 1, e, true)
            }
        }
        catch{
            this.seeComodos(selectedComodo);
            
            setTimeout(() => {
                self.setState({sended: true})
                try {
                    document.getElementById('btndoidprev').disabled = false;
                    document.getElementById("btndoidnext").disabled = false;
                } catch (error) {
                    
                }

            }, 1000);

            return
        }

        if(this.state.vistoria.tipo_vistoria == 1) {
            if (descricao === null || respostaSelect === -1 || descricao === '' || ftFile.length === 0) {
                this.toggleAviso("Preencha os campos");
                this.setState({ sended: true })
                try {
                    document.getElementById('btndoidprev').disabled = false;
                    document.getElementById("btndoidnext").disabled = false;
                } catch (error) {
                    
                }
    
                return;
            }
            else if (descricao.length > 10 && ftFile.length === 0) {
                this.toggleAviso("Insira um imagem");
                this.setState({ sended: true })
                try {
                    document.getElementById('btndoidprev').disabled = false;
                    document.getElementById("btndoidnext").disabled = false;
                } catch (error) {
                    
                }
    
                return;
            }
        }

        let response = {}
        response[comodosHouse[selectedComodo].id] = selectedPergunta.id;

        if (window.navigator.onLine) {
            let { data } = await api_vistoria.post(`/formulario/${this.props.match.params.id}`, { addedcaract, descricao, specialName, respostaSelect, perguntaId: selectedPergunta.id, nomeComodo: comodosHouse[selectedComodo].nome, comodoId: comodosHouse[selectedComodo].id });
            this.setState({ addedcaract: [], descricao: '', specialName: '', respostaSelect: -1 })
            
            if (data['visto']) {
                this.sendImages(data['visto'].insertId);
            }
        }

        else {
            let req = await window.navigator.serviceWorker.getRegistration();

            req.sync.register("SendPergunta")

            let idb = indexedDB.open("coleta", 3)
            let self = this;

            idb.onsuccess = (ax) => {
                const db = ax.target.result;

                let trans = db.transaction(['perguntas', "perguntasImagem"], "readwrite")

                let al = trans.objectStore('perguntas').put({ id: parseInt(selectedPergunta.id), addedcaract, descricao, specialName, respostaSelect, perguntaId: selectedPergunta.id, nomeComodo: comodosHouse[selectedComodo].nome, comodoId: comodosHouse[selectedComodo].id })
                self.setState({ addedcaract: [], descricao: '', specialName: '', respostaSelect: -1 })
                // trans.objectStore('perguntasOffline').put({id: idDados_vistoria, descricao, respostaSelect, addedcaract, perguntas_id :selectedPergunta.id})

                al.onsuccess = async (a) => {
                    trans.objectStore("perguntasImagem").put({ ftFile, idvis: al.result })

                    db.close()
                }
            }
        }

        try {
            this.setState({ respondidas: respondidas.concat(response), sended: true });

            let outros = this.state.outros.filter(y => y.comodo_id == this.state.comodosHouse[this.state.selectedComodo].id)

            if (parseInt(t) === 1) {
                let e = comodosHouse[selectedComodo].perguntas[askIndex + 1];
                if (e == undefined && outros.length == 0) {
                    this.seeComodos(selectedComodo);
                    // this.setState({sended: true})
                    setTimeout(() => {
                        self.setState({sended: true})
                        try {
                            document.getElementById('btndoidprev').disabled = false;
                            document.getElementById("btndoidnext").disabled = false;
                        } catch (error) {
                            
                        }

                    }, 1000);

                    return
                }

                else if (e == undefined && outros.length >= 1){
                    this.openSpecial(true, outros[0].id, this.state.outros.indexOf(outros[0]))
                    let self = this;

                    setTimeout(() => {
                        self.setState({ sended: true })
                        try {
                            document.getElementById('btndoidprev').disabled = false;
                            document.getElementById("btndoidnext").disabled = false;
                        } catch (error) {
                        }

                    }, 1000);
                    return
                }

                this.setState({ respondidas: respondidas.concat(response) });

                if (window.navigator.onLine) {
                    this.seePerguntas(askIndex + 1, e, true)
                    let self = this;

                    setTimeout(() => {
                        self.setState({sended: true})
                        try {
                            document.getElementById('btndoidprev').disabled = false;
                            document.getElementById("btndoidnext").disabled = false;
                        } catch (error) {
                            
                        }

                    }, 1000);

                }
                else {
                    let self = this;

                    setTimeout(() => {
                        self.seePerguntas(askIndex + 1, e, true)
                        
                        setTimeout(() => {
                            self.setState({sended: true})
                            try {
                                document.getElementById('btndoidprev').disabled = false;
                                document.getElementById("btndoidnext").disabled = false;
                            } catch (error) {
                                
                            }
    
                        }, 1000);
                        // self.setState({sended: true})
                    }, 175);
                }
            }

        }
        catch (e) {
            this.setState({ respondidas: respondidas.concat(response), sended: true });
            this.seeComodos(selectedComodo);
        }

        // this.setState({selection: false, comodos: true, perguntas: false, special: false, respondidas: respondidas.concat(response)});
    }

    /**
     * @description Pinta um quadrado de azul
     */
    blueSquare = (e) => {
        const { selectedComodo, comodosHouse, respondidas } = this.state;

        for (let i of respondidas) {
            if (comodosHouse[selectedComodo].id in i) {
                if (e.id === i[comodosHouse[selectedComodo].id]) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * @description Abra uma pergunta especial(outro)
     */
    openSpecial = async (finish = true, id, index) => {
        if (finish && window.navigator.onLine) {

            let re = await api_vistoria.post(`/formulario/${this.props.match.params.id}/getData/${id}`);
            this.setState({ ftHost: [], ftFile: [], indexoutros: index, selection: false, comodos: false, perguntas: false, allComodos: false, special: true, detalhes: false, es: false, seeNewComodos: false, avalmedidor: false, medidores: false, edit: true, respostaSelect: null, descricao: re.data.visto[0].resposta_aberta, idDados_vistoria: re.data.visto[0].id, specialName: re.data.visto[0].pergunta_especial, showCaracteristicas: false, addedcaract: re.data.addedcaract, nomecaract: '', hostcaract: [], estadoespecial: re.data.visto[0].estado_especial });
            return
        }
        else if (finish && !window.navigator.onLine) {
            let idb = indexedDB.open("coleta", 3);
            let self = this;

            idb.onsuccess = async (e) => {
                let db = e.target.result;
                let trans = db.transaction(['perguntasOffline', "perguntasUpdates"], "readwrite");

                let ax = trans.objectStore('perguntasOffline').get(id)
                // let ay = trans.objectStore('perguntasUpdates').get(id)

                ax.onsuccess = async (eh) => {
                    let dados = eh.target.result;

                    if (dados) {
                        self.setState({ ftHost: [], ftFile: [], indexoutros: index, selection: false, comodos: false, perguntas: false, allComodos: false, special: true, detalhes: false, es: false, seeNewComodos: false, avalmedidor: false, medidores: false, edit: true, respostaSelect: null, descricao: dados.resposta_aberta, idDados_vistoria: dados.id, specialName: dados.pergunta_especial, showCaracteristicas: false, addedcaract: dados.addedcaract, nomecaract: '', hostcaract: [], estadoespecial: dados.estadoespecial });
                    }
                    db.close()
                }
            }
            // this.setState({ ftHost: [], ftFile: [], indexoutros: index, selection: false, comodos: false, perguntas: false, allComodos: false, special: true, detalhes: false, es: false, seeNewComodos: false, avalmedidor: false, medidores: false, edit: true, respostaSelect: null, descricao: re.data.visto[0].resposta_aberta, idDados_vistoria: re.data.visto[0].id, specialName: re.data.visto[0].pergunta_especial, showCaracteristicas: false, addedcaract: re.data.addedcaract, nomecaract: '', hostcaract: [] });
        }
        else {
            this.setState({ ftHost: [], selection: false, comodos: false, perguntas: false, allComodos: false, special: true, detalhes: false, es: false, seeNewComodos: false, avalmedidor: false, specialName: '', edit: false, medidores: false, respostaSelect: null, descricao: '', ft: [], ftFile: [], showCaracteristicas: false, addedcaract: [], nomecaract: '', hostcaract: [] });
        }
    }

    /**
     * @description Envia uma pergunta especial(outro)
     */
    sendSpecialAsk = async () => {
        const { descricao, comodosHouse, selectedComodo, specialName, outros, ftFile, addedcaract } = this.state;

        if (descricao === null || specialName === '' || descricao === '' || ftFile.length === 0 || this.state.estadoespecial == -1) {
            this.toggleAviso("Preencha os campos");
            return;
        }

        if (window.navigator.onLine) {
            let { data } = await api_vistoria.post(`/formulario/${this.props.match.params.id}`, { addedcaract, descricao, specialName, nomeComodo: comodosHouse[selectedComodo].nome, comodoId: comodosHouse[selectedComodo].id, estadoespecial: this.state.estadoespecial });

            if (data['visto']) {

                this.sendImages(data['visto'].insertId);
            }
            this.setState({ outros: outros.concat(data.outro[0]), selection: false, comodos: true, perguntas: false, allComodos: false, special: false, detalhes: false, es: false, seeNewComodos: false, avalmedidor: false, medidores: false, specialName: '', descricao: '', ftFile: [], ft: [], estadoespecial: -1 })
        }
        else {
            let req = await window.navigator.serviceWorker.getRegistration();
            req.sync.register("SendPergunta")

            let self = this;

            let idb = indexedDB.open("coleta", 3)

            idb.onsuccess = (ax) => {
                const db = ax.target.result;

                let trans = db.transaction(['outros', "outrosImagem", "perguntasOffline"], "readwrite")
                let al = trans.objectStore('outros').put({ addedcaract, descricao, specialName, nomeComodo: comodosHouse[selectedComodo].nome, comodoId: comodosHouse[selectedComodo].id, estadoespecial: this.state.estadoespecial })

                al.onsuccess = async (a) => {
                    trans.objectStore("outros").delete(al.result);
                    let j = { id: "*" + al.result, addedcaract, resposta_aberta: descricao, pergunta_especial: specialName, nomeComodo: comodosHouse[selectedComodo].nome, comodo_id: comodosHouse[selectedComodo].id, perguntas_id: null, resposta_id: null, estadoespecial: this.state.estadoespecial }
                    trans.objectStore('outros').put(j)
                    trans.objectStore('perguntasOffline').put(j)

                    trans.objectStore("outrosImagem").put({ ftFile, idvis: "*" + al.result })
                    self.setState({ outros: outros.concat(j), selection: false, comodos: true, perguntas: false, allComodos: false, special: false, detalhes: false, es: false, seeNewComodos: false, avalmedidor: false, medidores: false, specialName: '', descricao: '', ftFile: [], ft: [], estadoespecial: -1 })
                    db.close()
                }
            }


        }
    }

    /**
     * @description Lista as perguntas especiais(outros)
     */
    showOthers = (e) => {
        const { comodosHouse, selectedComodo } = this.state;
        console.log(e);

        return e.filter(i => {
            return i.comodo_id == comodosHouse[selectedComodo].id;
        })
    }

    /**
     * @description Atualiza os dados de uma pergunta
     */
    updateAsk = async (t) => {
        const { descricao, respostaSelect, idDados_vistoria, ftFile, specialName, outros, indexoutros, addedcaract, askIndex, comodosHouse, selectedComodo, selectedPergunta } = this.state;
        try {
            if (t == -1 && !specialName) {
                let e = comodosHouse[selectedComodo].perguntas[askIndex - 1];
                if (e == undefined) {
                    this.seeComodos(selectedComodo);
                    return
                }

                this.seePerguntas(askIndex - 1, e, true)
            }
        }
        catch{
            this.seeComodos(selectedComodo);
            return
        }

        if (specialName) {
            outros[indexoutros].pergunta_especial = specialName;
        }

        if (navigator.onLine) {
            let { data } = await api_vistoria.post(`/formulario/${this.props.match.params.id}/update`, { addedcaract, descricao, respostaSelect, idDados_vistoria, specialName, estadoespecial: this.state.estadoespecial });

            if (data['visto']) {
                this.sendImages(idDados_vistoria);
            }
        }

        else {
            let req = await window.navigator.serviceWorker.getRegistration();

            req.sync.register("SendPergunta")

            let idb = indexedDB.open("coleta", 3)

            idb.onsuccess = (ax) => {
                const db = ax.target.result;

                let trans = db.transaction(['perguntasUpdates', "perguntasUpdatesImagem", "perguntasOffline"], "readwrite")

                let al = trans.objectStore('perguntasUpdates').put({ id: idDados_vistoria, addedcaract, descricao, respostaSelect, idDados_vistoria, specialName, estadoespecial: this.state.estadoespecial })

                trans.objectStore('perguntasOffline').put({ id: idDados_vistoria, descricao, respostaSelect, addedcaract, perguntas_id: selectedPergunta.id, estadoespecial: this.state.estadoespecial })

                al.onsuccess = async (a) => {
                    trans.objectStore("perguntasUpdatesImagem").put({ ftFile, idvis: al.result })
                    db.close()
                }
            }
        }

        let outrosComodos = this.state.outros.filter(y => y.comodo_id == this.state.comodosHouse[this.state.selectedComodo].id)

        try {
            if (t == 1 && !specialName) {
                let e = comodosHouse[selectedComodo].perguntas[askIndex + 1];
                if (e == undefined && outrosComodos.length == 0) {
                    this.seeComodos(selectedComodo);
                    return

                }

                else if (e == undefined && outrosComodos.length >= 1){
                    this.openSpecial(true, outrosComodos[0].id, this.state.outros.indexOf(outrosComodos[0]))
                    return
                }

                this.seePerguntas(askIndex + 1, e, true)
            }

        }
        catch{
            this.seeComodos(selectedComodo);
        }

        if (specialName) {
            let e = this.state.outros[this.state.outros.indexOf(outrosComodos[outrosComodos.indexOf(this.state.outros[this.state.indexoutros + 1])])]

            if (e == undefined){
                this.setState({ selection: false, comodos: true, perguntas: false, spacial: false });
            }
            else{
                this.openSpecial(true, e.id, this.state.outros.indexOf(e))
            }
        }
    }

    /**
     * @description Seleciona um comodo a ser apagado
     */
    addDel = async (index, id) => {
        const { deleteds } = this.state;

        this.setState({ deleteds: deleteds.concat(id) })
    }

    /**
     * @description Remove um comodo a que seria apaga (não apaga um comodo)
     */
    removeDel = async (index, id) => {
        const { deleteds } = this.state;

        for (let i = 0; i < deleteds.length; i++) {
            if (deleteds[i] == id) {
                deleteds.splice(i, 1);
                this.setState({ deleteds: deleteds })
                break;
            }
        }
    }

    /**
     * @description Apaga alguns comodos
     */
    finalDel = async () => {
        const { deleteds, comodosHouse, comodosList } = this.state;
        let { data } = await api_vistoria.post(`/formulario/${this.props.match.params.id}/del/com`, { id: deleteds });

        let com = comodosHouse.filter(e => {
            if (deleteds.includes(e.id)) {
                for (let i = 0; i < comodosList.length; i++) {
                    if (comodosList[i].id == e.comodo) {
                        comodosList[i].indexqt -= 1;

                        break;
                    }
                }
            }
            return !deleteds.includes(e.id)
        })

        for (let i = 0; i < comodosList.length; i++) {
            comodosList[i]['indexqt'] = 0
        }

        let list = [];
        for (let i = 0; i < com.length; i++) {
            let cindex;
            console.log(com);

            // eslint-disable-next-line no-loop-func
            comodosList.filter((e, index) => {
                if (e.id == com[i].comodo) {
                    cindex = index
                    return e.id == com[i].comodo;
                }
            })

            let nomes = com[i]['nome'].split(' ')
            nomes[nomes.length - 1] = `${comodosList[cindex].indexqt + 1}`;
            nomes = nomes.toString().replace(/,/g, ' ')

            comodosList[cindex].indexqt += 1

            list.push({
                "id": com[i].id,
                "comodo": com[i].comodo,
                "nome": nomes,
                "perguntas": com[i].perguntas
            });
        }

        com = list;

        this.setState({ indel: false, openModal: false, comodosList: comodosList, comodosHouse: com, deleteds: [] })

    }

    /**
     * @description Salva uma pergunta como "Não se aplica"
     */
    notaplay = async () => {
        const { comodosHouse, selectedComodo, selectedPergunta, respondidas, askIndex } = this.state;

        if (window.navigator.onLine) {
            let { data } = await api_vistoria.post(`/formulario/${this.props.match.params.id}`, { nomeComodo: comodosHouse[selectedComodo].nome, comodoId: comodosHouse[selectedComodo].id, perguntaId: selectedPergunta.id });
        }
        else {
            let req = await window.navigator.serviceWorker.getRegistration();

            req.sync.register("SendPergunta")

            let idb = indexedDB.open("coleta", 3)

            idb.onsuccess = (ax) => {
                const db = ax.target.result;
                let trans = db.transaction(['perguntas'], "readwrite")

                trans.objectStore('perguntas').put({ nomeComodo: comodosHouse[selectedComodo].nome, comodoId: comodosHouse[selectedComodo].id, perguntaId: selectedPergunta.id })
                db.close()
            }
        }

        let response = {}

        response[comodosHouse[selectedComodo].id] = selectedPergunta.id;

        this.setState({ respondidas: respondidas.concat(response) })

        try {
            let e = comodosHouse[selectedComodo].perguntas[askIndex + 1];
            if (e == undefined) {
                this.seeComodos(selectedComodo);
                return
            }
            this.seePerguntas(askIndex + 1, e, true)
        }
        catch{
            this.seeComodos(selectedComodo);
            return
        }
    }

    /**
     * @deprecated
     * @description Auto complete de caracteristica
     */
    autocompleteCarac = async (e) => {
        let { timeoutcontrol } = this.state;
        this.setState({ nomecaract: e });

        if (timeoutcontrol) {
            clearTimeout(timeoutcontrol);
        }

        this.setState({
            timeoutcontrol: setTimeout(async () => {
                let { data } = await api_vistoria.post('/formulario/autocomplete', { pad: e })
                this.setState({ caracList: data['caracteristicas'] })
            }, 450)
        })
    }

    /**
     * @deprecated
     */
    setidtype = async (e) => {
        const { nomecaract } = this.state;

        if (e != '-1') {
            this.addcaract({ 'tipo_caracteristicas_id': e, 'descricao': nomecaract });
        }
    }

    /**
     * @description Adiciona uma caracteristica
     */
    addcaract = async (e, u) => {
        let { addedcaract } = this.state;

        if (e.target.checked == true) {
            let c = { "tipo_caracteristicas_id": u.tipo_caracteristicas_id, "descricao": u.descricao };

            addedcaract.push(c)
            this.setState({ addedcaract: addedcaract })
        }
        else {
            addedcaract = addedcaract.filter(i => i.descricao != u.descricao);
            this.setState({ addedcaract: addedcaract })

        }
    }

    /**
     * @description Remove uma carateristica 
     */
    rmcaract = async (e) => {
        let { addedcaract } = this.state;

        addedcaract.splice(e, 1)

        this.setState({ addedcaract: addedcaract })

    }

    /**
     * @description Remove as caracteristica online de uma pergunta
     */
    rmHostcaract = async (e, d) => {
        let { hostcaract } = this.state;

        hostcaract.splice(e, 1);

        await api_vistoria.post("/formulario/autocomplete/del", { id: d.id, dados: d.dados_vistorias_id })

        this.setState({ hostcaract: hostcaract })

    }

    /**
     * @description Remove uma imagem de uma pergunta
     */
    rmimage = async (index) => {
        let { ft, ftFile } = this.state;

        ft.splice(index, 1)
        ftFile.splice(index, 1)

        this.setState({ ft: ft, avisoFT: false, ftFile: ftFile })
    }

    /**
     * @description Ajusta a imagem de acordo com a tela e abre ela num modal
     */
    fiximagesize = async (index) => {
        const { ft } = this.state;
        this.setState({ paintmaker: true });

        this.indeximg = index;


        setTimeout(() => {

            this.canvas = document.getElementById('nkonvas');
            let smenu = document.querySelector('div.smenucanvas');
            this.context = this.canvas.getContext("2d");

            let w = this.maxW * 0.875;
            let h = this.maxH * 0.875;

            let img = new Image()

            img.src = ft[index]

            img.onload = () => {

                let r = img.width / img.height

                let mw = ((h * img.width) / img.height);
                let mh = ((w * img.height) / img.width);

                if (mh <= h) {
                    img.height = mh;
                }
                else {
                    img.height = h;
                }
                if (mw <= w) {
                    img.width = mw;
                }
                else {
                    img.width = w;
                }

                this.xcanvas = this.canvas.width = img.width;
                this.ycanvas = this.canvas.height = img.height;

                smenu.style.width = img.width + "px";

                this.context.clearRect(0, 0, img.width, img.height);
                this.context.drawImage(img, 0, 0, img.width, img.height)

                this.canvasimg = img

                this.canvas.addEventListener("mousedown", this.startDraw)
                this.canvas.addEventListener("mouseup", this.finalDraw, false);
                this.canvas.addEventListener("mousemove", this.makeLines, false);

                this.canvas.addEventListener("touchstart", this.startDrawtouch)
                this.canvas.addEventListener("touchend", this.finalDrawtouch, false);
                this.canvas.addEventListener("touchmove", this.makeLinestouch, false);

            }

        }, 20);
    }

    /**
     * @description Fecha o modal
     */
    closefix = async (e) => {
        if (e == null || e.target.classList.contains('paintMaker')) {
            this.setState({ paintmaker: false, paintedLines: false, caractsModal: false })

            this.pre = [];
            this.nex = [];
        }
    }

    /**
     * @description Salva um desenho feito
     */
    saveScribbles = async (l, k) => {
        k = k || false

        if (!k) {
            this.nex = []
        }

        (l || this.pre).push(this.canvas.toDataURL());
    }

    /**
     * @description Volta/avança dentro de uma lista de imagem 
     */
    resScribbles = async (giver, taker) => {
        if (giver.length) {
            this.saveScribbles(taker, true)

            let r = giver.pop()
            let i = new Image()
            i.src = r;

            i.onload = () => {
                try {
                    this.context.clearRect(0, 0, this.xcanvas, this.ycanvas);
                    this.context.drawImage(this.canvasimg, 0, 0, this.xcanvas, this.ycanvas)
                    this.context.drawImage(i, 0, 0, this.xcanvas, this.ycanvas)
                    this.context = this.canvas.getContext("2d")
                }
                catch{ }
            }
        }
    }

    /**
     * @description Apaga um passo de desenho
     */
    undo = async () => {
        this.resScribbles(this.pre, this.nex)
    }

    /**
     * @description Reescreve um desenho apagado
     */
    redo = async () => {
        this.resScribbles(this.nex, this.pre)
    }

    /**
     * @description Inicia um desenho
     */
    startDrawtouch = async (e) => {
        let touch = e.touches[0];

        this.xline = touch.clientX - this.canvas.offsetLeft;
        this.yline = touch.clientY - this.canvas.offsetTop;

        this.pxline = touch.clientX - this.canvas.offsetLeft;
        this.pyline = touch.clientY - this.canvas.offsetTop;

        let mouseEvent = new MouseEvent("mousedown", {
            clientX: touch.clientX,
            clientY: touch.clientY
        });

        this.canvas.dispatchEvent(mouseEvent);
    }

    /**
     * @description Finaliza um desenho por toque
     */
    finalDrawtouch = async (e) => {
        let mouseEvent = new MouseEvent("mouseup", {});
        this.canvas.dispatchEvent(mouseEvent);
    }

    /**
     * @description Desenha linha por toque
     */
    makeLinestouch = async (e) => {
        let touch = e.touches[0];

        let mouseEvent = new MouseEvent("mousemove", {
            clientX: touch.clientX,
            clientY: touch.clientY
        });

        this.canvas.dispatchEvent(mouseEvent);
    }

    /**
     * @description Inicia um desenho
     */
    startDraw = async (e) => {
        if (!this.context.canvas) {
            this.context = this.canvas.getContext("2d");
        }

        this.pxline = this.xline;
        this.pyline = this.yline;
        this.xline = e.clientX - this.canvas.offsetLeft;
        this.yline = e.clientY - this.canvas.offsetTop;

        this.times = true
        this.saveScribbles()
        this.context.beginPath();
        this.context.moveTo(this.pxline, this.pyline);
        try {
            this.draw()
        }
        catch{ }
    }

    /**
     * @description Finaliza um desenho
     */
    finalDraw = async (e) => {
        this.times = false
    }

    /**
     * @description Desenha uma linha
     */
    makeLines = async (e) => {
        this.pxline = this.xline;
        this.pyline = this.yline;
        this.xline = e.clientX - this.canvas.offsetLeft;
        this.yline = e.clientY - this.canvas.offsetTop;
    }

    /**
     * @description Desenha o corpo e voltas de uma linha
     */
    draw = async (e) => {
        const { paintedLines, basecolorstroke } = this.state;

        if (this.times && paintedLines) {

            this.context.lineJoin = "round";
            this.context.lineTo(this.xline, this.yline);
            this.context.strokeStyle = basecolorstroke;
            this.context.lineWidth = 7;
            this.context.stroke();
            setTimeout(this.draw, 1)
        }
    }

    /**
     * @description Salva o estado de uma imagem com desenho
     */
    saveimage = async (index) => {
        let { ftFile, ft } = this.state;

        ft[this.indeximg] = this.canvas.toDataURL();

        let file

        this.canvas.toBlob((e) => {
            file = e
            file.lastModifiedDate = new Date();
            file.name = new Date().getDate() + ".jpg";

            ftFile[this.indeximg] = file

            this.pre = [];
            this.nex = [];

            this.setState({ ft: ft, ftFile: ftFile, paintmaker: false, paintedLines: false })

        }, 'image/jpeg', 1)
    }

    /**
     * @description Ativa/desativa o modo desenho
     */
    setblocked = async (id) => {
        let d = getComputedStyle(document.querySelector(`#${id}`)).getPropertyValue("display")
        if (d == "block") {
            document.querySelector(`#${id}`).style.display = "none";
        }
        else {
            document.querySelector(`#materiais`).style.display = "none";
            document.querySelector(`#cores`).style.display = "none";
            document.querySelector(`#${id}`).style.display = "block";

        }
    }

    openArea = async () => {
        this.setState({ selection: false, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: false, es: true, seeNewComodos: false, medidores: false, avalmedidor: false, })
    }

    openAreaComodo = async () => {
        if (window.navigator.onLine) {
            let { data } = await api_vistoria.post("/formulario/comodoInfo", { id: this.state.comodosHouse[this.state.selectedComodo].id });
            this.setState({ selection: false, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: false, areacomodo: true, seeNewComodos: false, medidores: false, avalmedidor: false, areacomodocampo: data.comodo[0].area })
        }
        else {
            let c = this.state.comodosHouse
            this.setState({ selection: false, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: false, areacomodo: true, seeNewComodos: false, medidores: false, avalmedidor: false, areacomodocampo: c[this.state.selectedComodo].area })
        }

    }

    openNewComodos = async () => {
        // if (!navigator.onLine) {
        //     this.setState({ avisomsg: "Esta função precisa de internet. Conecte-se e tente novamente.", aviso: true })
        //     return
        // }
        this.setState({ selection: false, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: false, es: false, seeNewComodos: true, medidores: false, avalmedidor: false, })
    }

    openMedidores = async () => {
        this.setState({ selection: false, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: false, es: false, seeNewComodos: false, medidores: true, avalmedidor: false, })
    }

    opendetalhes = async () => {
        this.setState({ selection: false, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: true, es: false, seeNewComodos: false, medidores: false, avalmedidor: false, })
    }

    openMedidorAval = async (index, id = null) => {
        if (!window.navigator.onLine && id) {
            this.setState({ aviso: true, avisomsg: "Esta função precisa de internet para funcionar. Conecte-se e tente novamente." })
            return
        }
        if (id) {
            this.setState({ indexmedidor: index, selection: false, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: false, es: false, seeNewComodos: false, medidores: false, avalmedidor: true });
            let { data } = await api_vistoria.post("/formulario/medidoreswork", { id, hash: this.props.match.params.id })
            this.setState({
                medidorcode: data.medidas[0].codigo,
                medidorleitura: data.medidas[0].Leitura,
                medidorligado: data.medidas[0].desligado,
                medidorImagesHost: data.med

            })
        }
        else {
            this.setState({ indexmedidor: index, selection: false, comodos: false, perguntas: false, allComodos: false, special: false, detalhes: false, es: false, seeNewComodos: false, medidores: false, avalmedidor: true });
            this.setState({
                medidorcode: '',
                medidorleitura: '',
                medidorligado: 1
            })
        }
    }

    nextOrPreviousMedidor = async (index, ignore = null) => {
        document.querySelector("#btnantmed").disabled = true
        document.querySelector("#btnproxmed").disabled = true

        let a = this.state.indexmedidor + index;

        if (this.state.medidorligado == -1 || this.state.medidorligado == 1){
            if ((!this.state.medidorcode || !this.state.medidorleitura || (this.state.medidorFiles.length == 0 && this.state.medidorImagesHost.length == 0)) && index == 1 && ignore == null) {
                this.setState({ avisomsg: "Preencha todos os campos", aviso: true });
                document.querySelector("#btnantmed").disabled = false
                document.querySelector("#btnproxmed").disabled = false
                return
            }
        }

        if (index == 1) {
            this.setState({
                medidasFeitas: this.state.medidasFeitas.concat(this.state.medidas[this.state.indexmedidor].id)
            })

            if (!ignore) {
                const { medidorFiles } = this.state;
                if (window.navigator.onLine) {
                    let { data } = await api_vistoria.post('/formulario/medidoradd', { codigo: this.state.medidorcode, leitura: this.state.medidorleitura, hash: this.props.match.params.id, id: this.state.medidas[this.state.indexmedidor].id, desligado: this.state.medidorligado });

                    for (let i of medidorFiles) {
                        let form = new FormData();
                        form.append('foo', i)
                        form.append('medi', data.dados)

                        await api_vistoria.post('/formulario/medidoraddimagem', form);
                    }

                    this.setState({ medidorFiles: [], medidorImages: [], medidorImagesHost: [] })
                }

                else {
                    let req = await window.navigator.serviceWorker.getRegistration();
                    req.sync.register("SendPergunta")

                    let idb = indexedDB.open("coleta", 3)

                    let self = this;

                    idb.onsuccess = (ax) => {
                        const db = ax.target.result;

                        let trans = db.transaction(['medidoresImagem', "medidores"], "readwrite")
                        let al = trans.objectStore('medidores').put({ codigo: self.state.medidorcode, leitura: self.state.medidorleitura, hash: self.props.match.params.id, id: self.state.medidas[self.state.indexmedidor].id, desligado: self.state.medidorligado })

                        al.onsuccess = async (a) => {
                            trans.objectStore("medidoresImagem").put({ medidorFiles, idmed: al.result })
                            self.setState({ medidorFiles: [], medidorImages: [], medidorImagesHost: [] })
                            db.close()
                        }
                    }
                }
            }
            else if (window.navigator.onLine) {
                await api_vistoria.post('/formulario/medidoradd', { codigo: null, leitura: null, hash: this.props.match.params.id, id: this.state.medidas[this.state.indexmedidor].id, desligado: null });
            }
            else {
                let req = await window.navigator.serviceWorker.getRegistration();
                req.sync.register("SendPergunta")

                let idb = indexedDB.open("coleta", 3)

                let self = this;

                idb.onsuccess = (ax) => {
                    const db = ax.target.result;

                    let trans = db.transaction(["medidores"], "readwrite")
                    let al = trans.objectStore('medidores').put({ codigo: null, leitura: null, hash: self.props.match.params.id, id: self.state.medidas[self.state.indexmedidor].id, desligado: null })
                    db.close()

                }
            }
        }

        if (a == -1 || this.state.medidas.length == a) {
            this.openMedidores();
            return
        }

        else if (window.navigator.onLine) {
            this.setState({ indexmedidor: a })
        }
        else {
            setTimeout(() => {
                this.setState({ indexmedidor: a })

            }, 100);
        }

        if (window.navigator.onLine) {
            let { data } = await api_vistoria.post("/formulario/medidoreswork", { id: this.state.medidas[a].id, hash: this.props.match.params.id });

            if (data.medidas.length > 0) {
                this.setState({
                    medidorcode: data.medidas[0].codigo,
                    medidorleitura: data.medidas[0].Leitura,
                    medidorligado: data.medidas[0].desligado,
                    medidorImagesHost: data.med
                })
            }

            else {
                this.setState({
                    medidorligado: 1, medidorcode: '', medidorleitura: '', medidorImagesHost: []
                })
            }
            document.querySelector("#btnantmed").disabled = false
            document.querySelector("#btnproxmed").disabled = false

        }
        else {
            setTimeout(() => {
                this.setState({
                    medidorligado: 1, medidorcode: '', medidorleitura: '', medidorImagesHost: []
                })

                document.querySelector("#btnantmed").disabled = false
                document.querySelector("#btnproxmed").disabled = false
    
            }, 100);
        }

    }

    blueSquareMedidor = (id) => {
        if (this.state.medidasFeitas.includes(id)) {
            return true
        }

        return false
    }

    saveArea = async () => {
        let v = this.state.vistoria;

        v.area = this.state.area || ''
        this.setState({ vistoria: v })

        if (window.navigator.onLine) {
            await api_vistoria.post('/formulario/ae', { hash: this.props.match.params.id, area: this.state.area || '' })
        }
        else {
            let req = await window.navigator.serviceWorker.getRegistration();
            let self = this;

            req.sync.register("SendPergunta")

            let idb = indexedDB.open("coleta", 3)
            idb.onsuccess = (ax) => {
                const db = ax.target.result;

                let trans = db.transaction(['metragemExterna'], "readwrite")
                trans.objectStore('metragemExterna').put({ hash: self.props.match.params.id, area: self.state.area || ''})
                db.close()
            }
        }

        this.openMedidores();
    }

    saveAreaComodo = async () => {
        let c = this.state.comodosHouse
        c[this.state.selectedComodo].area = this.state.areacomodocampo;
        c[this.state.selectedComodo].nome = this.state.newAmbienteNome;

        this.setState({ comodosHouse: c });

        if (window.navigator.onLine) {
            await api_vistoria.post('/formulario/nomecomodo', { id: this.state.comodosHouse[this.state.selectedComodo].id, nome: this.state.newAmbienteNome })
            await api_vistoria.post('/formulario/areacomodo', { id: this.state.comodosHouse[this.state.selectedComodo].id, area: this.state.areacomodocampo })
        }
        else {
            let req = await window.navigator.serviceWorker.getRegistration();
            let self = this;

            req.sync.register("SendPergunta")

            let idb = indexedDB.open("coleta", 3)
            idb.onsuccess = (ax) => {
                const db = ax.target.result;

                let trans = db.transaction(['metragemComodo'], "readwrite")
                trans.objectStore('metragemComodo').put({ id: self.state.comodosHouse[self.state.selectedComodo].id, area: self.state.areacomodocampo })
                db.close()
            }
        }
        this.seeComodos();
    }

    appendImageMedidor = async (e, i) => {
        this.setState({ medidorImages: this.state.medidorImages.concat(e.target.result), medidorFiles: this.state.medidorFiles.concat(i) });
    }

    addMedidorImage = async (e) => {
        for (let i of e.target.files) {
            if (!i.type.match('image.*')) {
                continue;
            }

            let rd = new FileReader();

            rd.onload = (j) => this.appendImageMedidor(j, i);
            rd.readAsDataURL(i)
        }
    }

    rmimageME = async (index) => {
        let { medidorFiles, medidorImages, ismedHost, medidorImagesHost } = this.state;

        if (ismedHost) {
            await api_vistoria.post("/formulario/medidordeleteimage", { id: medidorImagesHost[index].id });
            medidorImagesHost.splice(index, 1)
            this.setState({ medidorImagesHost: medidorImagesHost, ismedHost: false, avisoME: false })
        }
        else {
            medidorFiles.splice(index, 1)
            medidorImages.splice(index, 1)

            this.setState({ ft: medidorImages, medidorFiles: medidorFiles, avisoME: false })

        }

    }

    saveMobiFaxi = async () => {
        if (window.navigator.onLine) {
            await api_vistoria.post("/formulario/savemobi/" + this.props.match.params.id, { faxi: this.state.faxi, mobi: this.state.mobi })
        }
        else {
            let req = await window.navigator.serviceWorker.getRegistration();
            let self = this;

            req.sync.register("SendPergunta")

            let idb = indexedDB.open("coleta", 3)
            idb.onsuccess = (ax) => {
                const db = ax.target.result;

                let trans = db.transaction(['detalhes'], "readwrite")
                trans.objectStore('detalhes').put({ faxi: self.state.faxi, mobi: self.state.mobi })
                db.close()
            }
        }
        let v = this.state.vistoria

        v.faxi = this.state.faxi
        v.faxinado = this.state.faxi || 0
        v.mobi = this.state.mobi

        this.setState({ vistoria: v })

        this.openMedidores()
    }

    blueLive = (e) => {
        let perid = [];
        console.log(e);

        for (let i of e['perguntas']) {
            perid.push(i.id)
        }

        let p = this.state.respondidas.filter(y => y[`${e.id}`] && perid.includes(y[`${e.id}`]));

        if (p.length < e['perguntas'].length) {
            return false
        }

        return true
    }

    blueEX = () => {
        for (let i of this.state.comodosHouse){
            if (!this.blueLive(i)) return false
        }

        return true
    }

    saveObs = async () => {
        if (window.navigator.onLine) {
            await api_vistoria.post("/formulario/obssave", { 
                id: this.state.vistoria.id,
                obs: this.state.observacao_vis    
            })
        }
        else{
            let req = await window.navigator.serviceWorker.getRegistration();
            let self = this;

            req.sync.register("SendPergunta")

            let idb = indexedDB.open("coleta", 3)
            idb.onsuccess = (ax) => {
                const db = ax.target.result;

                let trans = db.transaction(['obs'], "readwrite")
                trans.objectStore('obs').put({ obs: self.state.observacao_vis, id: self.state.vistoria.id })
                db.close()
            }
        }

        this.setState({
            savedObs: true,
            obsVistoria: false,
            medidores: true
        })

    }

    cloneCom = async (e, index) => {
        console.log(e, index);
        console.log(this.state.comodosHouse[index].perguntas);

        await api_vistoria.post("/formulario/clone", { 
            id: e.id,
        })
        
        alert("Ambiente clonado")
        await this.fetchFormRequest()

        this.setState({
            selection: false,
            inClone: false,
            allComodos: true
        })
    }

    nextComodo = () => {
        if (!this.state.comodosHouse[this.state.selectedComodo + 1]){
            this.seeAllComodos()
            return
        }

        this.setState({
            selectedComodo: this.state.selectedComodo + 1
        })
    }

    render() {
        const {
            openModal, deleteds, specialName, edit, respostaSelect,
            descricao, outros, special, imobiliaria, aviso, final,
            avisomsg, imovelData, allComodos, perguntas, done, vistoria,
            selection, comodos, ft, comodosList, selectedComodo,
            selectedPergunta, comodosHouse, indel, avisoME,
            caracList, tiposCaract, addedcaract, finalModal,
            hostcaract, paintmaker, paintedLines, ftHost, basecolorstroke,
            caractsModal, avisoFT, medidores, medidas, avalmedidor, indexmedidor, seeNewComodos
        } = this.state;

        return (
            <>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous" />
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>
                <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800|Roboto+Slab&display=swap" rel="stylesheet" />

                <link rel="stylesheet" href="/Form/solicitacao.css" />
                <link rel="stylesheet" href="/Form/comodo.css" />
                <link rel="stylesheet" href="/Form/com-desc.css" />
                <link rel="stylesheet" href="/Form/descricao.css" />
                <link rel="stylesheet" href="/Form/aviso.css" />
                <link rel="stylesheet" href="/Form/selecao.css" />

                <Modal isOpen={openModal}>
                    <ModalBody>
                        <p>Deseja realmete excluir estes itens?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.finalDel}>Sim</Button>
                        <Button color="danger" onClick={e => { this.setState({ openModal: !openModal, indel: false }) }}>Não</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={finalModal}>
                    <ModalBody>
                        <p>Deseja realmete enviar a vistoria?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.finalVistoria}>Enviar</Button>
                        <Button color="danger" onClick={e => { this.setState({ finalModal: !finalModal, indel: false }) }}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
                <div className="allwhite">

                    {
                        vistoria.status === "Execução" && avalmedidor === true && done === false &&
                        <div className="perguntasVistorias App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-desc">
                                <div className="com-desc-head">
                                    <strong>Coleta inicial: </strong>
                                    <div className="item-select">
                                        <p>{medidas[indexmedidor].nome}</p>
                                    </div>
                                </div>
                                <div className="com-desc-itens">

                                    <form>
                                        <button type="button" className="nabtn" onClick={a => this.nextOrPreviousMedidor(1, 1)}>Não se aplica</button>
                                        <div className="btn-switch">
                                            <input checked={this.state.medidorligado == 1} onChange={a => this.setState({ medidorligado: 1 })} type="radio" name="swt" className="switch-check" id="swt-1" />
                                            <input checked={this.state.medidorligado == 0} onChange={a => this.setState({ medidorligado: 0 })} type="radio" name="swt" className="switch-check selectImp" id="swt-2" />
                                            <input checked={this.state.medidorligado == 2} onChange={a => this.setState({ medidorligado: 2 })} type="radio" name="swt" className="switch-check selectImp2" id="swt-3" />

                                            <div className="colarized-switch">
                                            </div>
                                            <label for="swt-1">
                                                <p className="on">LIGADO</p>
                                            </label>
                                            <label for="swt-2">
                                                <p className="off">DESLIGADO</p>
                                            </label>
                                            <label for="swt-3">
                                                <p className="off2">COMPARTILHADO</p>
                                            </label>
                                        </div>

                                        <br />
                                        <div className="desc-item">
                                            <input value={this.state.medidorcode} type="text" placeholder="Código do medidor" onChange={e => { this.setState({ medidorcode: e.target.value }) }} />

                                        </div>
                                        <br />
                                        <div className="desc-item">
                                            <input value={this.state.medidorleitura} type="text" placeholder="Leitura do medido" onChange={e => { this.setState({ medidorleitura: e.target.value }) }} />
                                        </div>
                                        <br />
                                        <div className="desc-item-cam">
                                            <Input hidden className="btn-cam" multiple id="files" accept="image/*" type="file" onChange={(e) => this.addMedidorImage(e)} />
                                            <label htmlFor="files" className="btn-cam">
                                                Inserir fotos
                                            <i className="fas fa-camera"></i>
                                            </label>
                                        </div>
                                        <div id="list">
                                            {
                                                this.state.medidorImagesHost.map((e, index) => (
                                                    <div className="fts">
                                                        <div className="ftitem">
                                                            <i className="fas fa-times" onClick={a => this.setState({ avisoME: true, indexft: index, ismedHost: true })}></i>
                                                        </div>
                                                        <img src={e.caminho} alt="" />
                                                    </div>
                                                ))
                                            }
                                            {
                                                this.state.medidorImages.map((e, index) => (
                                                    <div className="fts">
                                                        <div className="ftitem">
                                                            <i className="fas fa-times" onClick={a => this.setState({ avisoME: true, indexft: index })}></i>
                                                        </div>
                                                        <img src={e} alt="" />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </form>
                                </div>
                            </section>
                            <footer>
                                <button id="btnantmed" onClick={r => this.nextOrPreviousMedidor(-1)}><i className="fas fa-arrow-left"></i>&nbsp;Anterior</button>
                                <button id="btnproxmed" onClick={r => this.nextOrPreviousMedidor(1)}>Próximo&nbsp;<i className="fas fa-arrow-right"></i></button>
                            </footer>
                        </div>
                    }

                    {
                        vistoria.status === "Execução" && this.state.detalhes === true && done === false &&
                        <div className="perguntasVistorias App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-desc">
                                <div className="com-desc-head">
                                    <strong>Detalhes do imóvel: </strong>
                                </div>
                                <label className="unmargined">Mobiliado</label>
                                <div className="btn-switch">
                                    <input checked={this.state.mobi} onChange={a => this.setState({ mobi: 1 })} type="radio" name="mobi" className="switch-check" id="uuu-1" />
                                    <input checked={!this.state.mobi} onChange={a => this.setState({ mobi: 0 })} type="radio" name="mobi" className="switch-check selectImp" id="uuu-2" />

                                    <div className="colarized-switch-50">
                                    </div>
                                    <label for="uuu-1">
                                        <p className="on">SIM</p>
                                    </label>
                                    <label for="uuu-2">
                                        <p className="off">NÃO</p>
                                    </label>
                                </div>
                                <br />

                                <label className="unmargined">Faxinado</label>
                                <div className="btn-switch">
                                    <input checked={this.state.faxi} onChange={a => this.setState({ faxi: 1 })} type="radio" name="faxi" className="switch-check " id="da-1" />
                                    <input checked={!this.state.faxi} onChange={a => this.setState({ faxi: 0 })} type="radio" name="faxi" className="switch-check selectImp" id="da-2" />

                                    <div className="colarized-switch-50">
                                    </div>
                                    <label for="da-1">
                                        <p className="on">SIM</p>
                                    </label>
                                    <label for="da-2">
                                        <p className="off">NÃO</p>
                                    </label>
                                </div>
                            </section>
                            <footer>
                                <button onClick={this.openMedidores}><i className="fas fa-arrow-left"></i>&nbsp;Voltar</button>
                                <button onClick={this.saveMobiFaxi}>Salvar&nbsp;<i className="fas fa-arrow-right"></i></button>
                            </footer>
                        </div>
                    }

                    {
                        vistoria.status === "Execução" && this.state.es === true && done === false &&
                        <div className="perguntasVistorias App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-desc">
                                <div className="com-desc-head">
                                    <strong>Metragem externa: </strong>
                                    {/* <div className="item-select">
                                <p>{medidas[indexmedidor].nome}</p>
                            </div> */}
                                </div>
                                <div className="com-desc-itens">

                                    <form>
                                        <button type="button" className="nabtn" onClick={this.saveArea}>Não se aplica</button>
                                        <label className="unmargined">Infome a metragem externa em m²</label>
                                        <div className="desc-item">
                                            <input value={this.state.area} type="number" step="0.001" placeholder="Tamanho da metragem de externa em m²" onChange={e => { this.setState({ area: e.target.value }) }} />
                                        </div>
                                    </form>
                                </div>
                            </section>
                            <footer>
                                <button onClick={this.openMedidores}><i className="fas fa-arrow-left"></i>&nbsp;Voltar</button>
                                <button onClick={this.saveArea}>Salvar&nbsp;<i className="fas fa-arrow-right"></i></button>
                            </footer>
                        </div>
                    }

                    {
                        vistoria.status === "Execução" && this.state.obsVistoria === true && done === false &&
                        <div className="perguntasVistorias App paddingB">
                            <header>
                                <a href="">
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src={require("../assets/img/Auth/logo.png")} alt="" />
                            </header>
                            <section id="com-desc">
                                <div className="com-desc-head">
                                    <strong>Observações: </strong>
                                </div>
                                <div className="com-desc-itens">

                                    <form>
                                        <label className="unmargined">Observações</label>
                                        
                                        <div className="desc-item-desc">
                                            <textarea value={this.state.observacao_vis} onChange={e => { this.setState({ observacao_vis: e.target.value }) }}></textarea>
                                        </div>
                                    </form>
                                </div>
                            </section>
                            <footer>
                                <button onClick={this.openMedidores}><i className="fas fa-arrow-left"></i>&nbsp;Voltar</button>
                                <button onClick={this.saveObs}>Salvar&nbsp;<i className="fas fa-arrow-right"></i></button>
                            </footer>
                        </div>
                    }

                    {
                        vistoria.status === "Execução" && medidores === true && done === false &&
                        <div className="comodosVistoria App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-area">
                                <div className="com-head">
                                    <p>Coleta inicial</p>
                                </div>
                                <div className="com-sel">
                                    {
                                        imovelData.area_externa != null && imovelData.area_externa >= 0 ? (
                                            <div className={vistoria.area != null ? ("com-sel-col cor-col-check") : ("com-sel-col")}>
                                                <button onClick={this.openArea}>Metragem externa</button>
                                            </div>
                                        ) : (null)
                                    }
                                    <div className={vistoria.faxinado != null || vistoria.mobiliado != null ? ("com-sel-col cor-col-check") : ("com-sel-col")}>
                                        <button onClick={this.opendetalhes}>Detalhe do imóvel</button>
                                    </div>
                                    {
                                        medidas.map((e, index) => (
                                            this.blueSquareMedidor(e.id) == true ? (
                                                <div className="com-sel-col cor-col-check">
                                                    <button onClick={a => this.openMedidorAval(index, e.id)}>{e.nome}</button>
                                                </div>
                                            ) : (
                                                    <div className="com-sel-col">
                                                        <button onClick={a => this.openMedidorAval(index)}>{e.nome}</button>
                                                    </div>
                                                )
                                        ))
                                    }

                                    <div className={this.state.savedObs != '' ? ("com-sel-col cor-col-check") : ("com-sel-col")}>
                                        <button onClick={a => this.setState({obsVistoria: true, medidores: false})}>Observações</button>
                                    </div>
                                </div>
                            </section>
                            <footer className="App">
                                <button onClick={this.seeInit}>Voltar</button>
                                {
                                    medidas.map((e, index) => (this.blueSquareMedidor(e.id) == true)).every(y => y) && vistoria.faxinado != null ? (
                                        <button onClick={this.seeAllComodos}>Avançar</button>
                                    ) : (null)
                                }
                            </footer>
                        </div>
                    }

                    {
                        vistoria.status === "Execução" && seeNewComodos === true && done === false &&
                        <div className="comodosVistoria App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-area">
                                <div className="com-head">
                                    <p>Ambientes</p>
                                </div>
                                <div className="com-sel">
                                    {
                                        comodosList.map((e, index) => (
                                            <div className="com-sel-col" onClick={i => this.changeComodo(index)}>
                                                <button>{e.nome}</button>
                                            </div>
                                            // <option value={index}>{e.nome}</option>
                                        ))
                                    }
                                </div>
                            </section>
                            <footer className="App">
                                <button onClick={this.seeInit}>Voltar</button>
                            </footer>
                        </div>
                    }

                    {
                        vistoria.status === "Execução" && allComodos === true && done === false &&
                        <div className="comodosVistoria App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-area">
                                <div className="com-head">
                                    <p>Ambientes existentes</p>
                                </div>
                                <div className="com-sel">
                                {
                                        comodosHouse.map((e, index) => (
                                            <div className={this.blueLive(e) ? "com-sel-col cor-col-check" : "com-sel-col"}>
                                                {
                                                    this.state.inClone ? (
                                                        <button onClick={i => { this.cloneCom(e, index) }}>{e.nome}</button>
                                                    ) : (
                                                        indel == false ? (
                                                            <button onClick={i => { this.setComodo(index) }}>{e.nome}</button>
                                                        ) : (
                                                            deleteds.includes(e.id) ? (
                                                                <button onClick={i => { this.removeDel(index, e.id) }} className="letting">{e.nome}</button>
                                                            ) : (
                                                                <button onClick={i => { this.addDel(index, e.id) }}>{e.nome}</button>
                                                            )
                                                        )
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                    
                                    {
                                        this.state.inClone ? (
                                            <>
                                                <div className="com-sel-col sle-out high-dark" onClick={a => this.setState({inClone: false})}>
                                                    Cancelar
                                                </div>
                                                <div className="com-sel-col sle-out high-dark text-center">
                                                    Selecione um ambiente para clonar
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="com-sel-col sle-out high-dark">
                                                    <button onClick={this.openNewComodos}>+</button>
                                                </div>
                                                {
                                                    window.navigator.onLine ? (
                                                        <div className="com-sel-col sle-out high-dark" onClick={a => this.setState({inClone: true})}>
                                                            Clonar
                                                        </div>
                                                    ) : (null)
                                                }
                                            </>
                                        )
                                    }
                                </div>
                            </section>
                            <footer className="App">
                                <button onClick={this.seeInit}>Voltar</button>
                                {
                                    indel == false ? (
                                        <button onClick={e => this.setState({ indel: !indel })} className="dancepowder" >Apagar</button>
                                    ) : (
                                            <button className="dancepowder blazer" onClick={e => this.setState({ openModal: true })}>Finalizar</button>
                                        )
                                }
                            </footer>
                        </div>
                    }

                    {
                        vistoria.status == "Aberto" && done === false &&
                        <div className="final">
                            <p>Aguardando o aceite</p>
                        </div>
                    }

                    {
                        vistoria.status === "Aceito" && done === false &&
                        <div className="solicitacao ">
                            <div className="header">
                                <img src='/Auth/logo.png' alt="" />
                            </div>
                            <section id="inicio">
                                <div id="desenho">
                                    <div className="redondo">
                                        <img src='/Form/desenho.svg' alt="" />
                                    </div>
                                </div>
                                <h1>Solicitação de vistoria</h1>
                                <div className="ini-info">
                                    <div className="ini-info-head">
                                        <i className="fas fa-building"></i>
                                        <p><span>Imobiliária</span></p>
                                    </div>
                                    <p className="ini-info-p">{imobiliaria.razao_social}</p>

                                </div>
                                <div className="ini-info">
                                    <div className="ini-info-head">
                                        <i className="fas fa-home"></i>
                                        <p><span>Endereço</span></p>
                                    </div>
                                    <p className="ini-info-p">{imovelData.rua_imovel}, {imovelData.numero_imovel}, {imovelData.complemento} - {imovelData.cidade}, {imovelData.estado}</p>
                                    <p className="ini-info-p">{imovelData.titulo_imovel}</p>
                                </div>
                                <div className="ini-info">
                                    <div className="ini-info-head">
                                        <i className="fas fa-ruler-combined"></i>
                                        <p><span>Metragem</span></p>
                                    </div>
                                    <p className="ini-info-p">{imovelData.metragem} m²</p>
                                </div>

                                <div className="ini-info">
                                    <div className="ini-info-head">
                                        <i className="fas fa-calendar-alt"></i>
                                        <p><span>Data</span></p>
                                    </div>
                                    <p className="ini-info-p">{this.formatDate(vistoria.data_agendada)}</p>
                                </div>
                                <div className="ini-info">
                                    <div className="ini-info-head">
                                        <i className="far fa-clock"></i>
                                        <p><span>Horário</span></p>
                                    </div>
                                    <p className="ini-info-p">{this.formatDate(vistoria.hora_agendada)}</p>
                                </div>
                                <h2>Retirada da chave</h2>
                                <div className="ini-info">
                                    <div className="ini-info-head">
                                        <i className="fas fa-home"></i>
                                        <p><span>Endereço</span></p>
                                    </div>
                                    <p className="ini-info-p">{vistoria.endereco_retirada}</p>
                                </div>
                                <div className="ini-info">
                                    <div className="ini-info-head">
                                        <i className="far fa-address-book"></i>
                                        <p><span>Contato</span></p>
                                    </div>
                                    <p className="ini-info-p">{vistoria.contato_retirada}</p>
                                </div>
                                <div className="ini-info">
                                    <div className="ini-info-head">
                                        <i className="fas fa-address-card"></i>
                                        <p><span>Observção</span></p>
                                    </div>
                                    <p className="ini-info-p">{vistoria.observacao}</p>
                                </div>
                            </section>
                            <footer>
                                <button onClick={this.accpetView}>Iniciar vistoria</button>

                                {/* <a href="#" onClick={r => this.seeInit()}>Recusar</a> */}
                                {/* <a href="#" onClick={r => this.seeInit()}>Aceitar</a> */}
                            </footer>
                        </div>
                    }
                    {
                        vistoria.status === "Execução" && selection === true && done === false &&
                        <div className="selecaoVistoria paddingB">
                            <header>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="sel-com">

                                <div className="btn-com">
                                    <button onClick={this.openMedidores}>Coleta inicial<i className="fas fa-arrow-right"></i></button>
                                </div>
                                <br />
                                {
                                    this.state.medidas.filter(r => !this.state.medidasFeitas.includes(r.id)).length == 0 ? (
                                        this.blueEX() ? 
                            
                                        (<div className="btn-com">
                                            <button onClick={this.seeAllComodos}>Ver ambientes existentes<i className="fas fa-arrow-right"></i></button>
                                        </div>)
                                    :
                                        (<div className="graybtn btn-com">
                                            <button className="graybtn" onClick={this.seeAllComodos}>Ver ambientes existentes<i className="fas fa-arrow-right"></i></button>
                                        </div>)
                                    ) : (null)
                                }
                            </section>
                            <br />
                            <footer>
                                {
                                    this.state.online ? (
                                        <button onClick={a => this.setState({finalModal: true})}>confirmar envio</button>
                                    ) : (

                                        <button>confirmar envio</button>
                                    )
                                }
                            </footer>
                        </div>
                    }

                    {
                        vistoria.status === "Execução" && this.state.areacomodo === true && done === false &&
                        <div className="perguntasVistorias App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-desc">
                                <div className="com-desc-head">
                                    <strong>{comodosHouse[selectedComodo].nome}</strong>
                                    <div className="item-select">
                                        <p>Detalhes do ambiente</p>
                                    </div>
                                </div>
                                <div className="com-desc-itens">

                                    <form>
                                        <button type="button" className="nabtn" onClick={this.seeComodos}>Não se aplica</button>
                                        <label className="unmargined">Nome do ambiente</label>
                                        <div className="desc-item mb-2">
                                            <input value={this.state.newAmbienteNome} onChange={a => this.setState({ newAmbienteNome: a.target.value })} placeholder="Digite o nome do ambiente" />
                                        </div>

                                        <label className="unmargined">Informe a metragem do ambiente em m²</label>
                                        <div className="desc-item">
                                            <input value={this.state.areacomodocampo} onChange={a => this.setState({ areacomodocampo: a.target.value })} type="number" step="0.001" placeholder="Metragem do ambiente em m²" />
                                        </div>
                                    </form>
                                </div>
                            </section>
                            <footer>
                                <button onClick={this.seeComodos}>Voltar</button>
                                <button onClick={this.saveAreaComodo}>Salvar</button>
                            </footer>
                        </div>
                    }

                    {
                        vistoria.status === "Execução" && comodos === true && done === false &&
                        <div className="comodosVistoria App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-area">
                                <div className="com-head">
                                    <strong>Ambiente:</strong>
                                    <p>{comodosHouse[selectedComodo].nome}</p>
                                </div>
                                <div className="com-sel">
                                    <div className={comodosHouse[selectedComodo].area ? ("com-sel-col cor-col-check") : ("com-sel-col")}>
                                        <button onClick={this.openAreaComodo}>Metragem do ambiente</button>
                                    </div>

                                    {
                                        comodosHouse[selectedComodo].perguntas.map((e, index) => (
                                            this.blueSquare(e) == true ? (
                                                <div className="com-sel-col cor-col-check">
                                                    <button onClick={() => { this.seePerguntas(index, e, true) }}>{e.descricao}</button>
                                                </div>
                                            ) : (
                                                    <div className="com-sel-col">
                                                        <button onClick={() => { this.seePerguntas(index, e) }}>{e.descricao}</button>
                                                    </div>
                                                )
                                        ))
                                    }

                                    {
                                        outros.map((i, index) => (
                                            i.comodo_id == comodosHouse[selectedComodo].id ? (
                                                <div className="com-sel-col cor-col-check">
                                                    <button onClick={e => { this.openSpecial(true, i.id, index) }}>{i.pergunta_especial}</button>
                                                </div>
                                            ) : (
                                                    null
                                                )
                                        ))

                                    }

                                    <div className="com-sel-col sle-out high-dark">
                                        <button onClick={e => { this.openSpecial(false) }}>+</button>
                                    </div>

                                </div>
                            </section>
                            <footer className="App">
                                <button onClick={this.seeAllComodos}>Voltar</button>
                                <button onClick={this.nextComodo}>Próximo</button>

                            </footer>
                        </div>
                    }

                    {
                        vistoria.status === "Execução" && perguntas === true && done === false &&
                        <div className="perguntasVistorias App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-desc">
                                <div className="com-desc-head">
                                    <strong>{comodosHouse[selectedComodo].nome}</strong>
                                    <div className="item-select">
                                        <p>{selectedPergunta.descricao}</p>
                                    </div>
                                </div>
                                <div className="com-desc-itens">

                                    <form>
                                        <button type="button" className="nabtn" onClick={this.notaplay}>Não se aplica</button>
                                        
                                        <p className="mb-0">Estado</p>
                                        <div className="desc-item">
                                            <select value={respostaSelect} onChange={e => { this.setState({ respostaSelect: e.target.value }) }}>
                                                <option value="-1" defaultChecked>Selecione</option>
                                                {
                                                    this.respostasPergunta().map((e, index) => (
                                                        <option value={e.id}>{e.descricao}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div>
                                            <p className="mb-0">Materiais</p>
                                            <div className="btn-itens" onClick={() => this.setState({ caractsModal: true, typecaracs: 2 })}>
                                                <i className="fas fa-layer-group"></i>
                                                <p>Materiais</p>
                                            </div>
                                            <p>
                                                {
                                                    this.state.addedcaract.filter(y => y.tipo_caracteristicas_id == 2).map(y => y.descricao).join(", ")
                                                }
                                            </p>
                                        </div>

                                        <div>
                                            <p className="mb-0">Cores</p>
                                            <div className="btn-itens" onClick={() => this.setState({ caractsModal: true, typecaracs: 1 })}>
                                                <i className="fas fa-palette"></i>
                                                <p>
                                                    Cores
                                                </p>
                                            </div>
                                            <p>
                                                {
                                                    this.state.addedcaract.filter(y => y.tipo_caracteristicas_id == 1).map(y => y.descricao).join(", ")
                                                }
                                            </p>
                                        </div>

                                        <p className="mb-0">Foto</p>
                                        <div className="desc-item-cam">
                                            <Input hidden className="btn-cam" multiple id="files" accept="image/*" type="file" onChange={(e) => this.addImage(e)} />
                                            <label htmlFor="files" className="btn-cam">
                                                Inserir fotos
                                            <i className="fas fa-camera"></i>
                                            </label>
                                        </div>
                                        <div id="list">
                                            {
                                                ftHost.map((e, index) => (
                                                    <div className="fts">
                                                        <div className="ftitem">
                                                            <i className="fas fa-times"></i>
                                                        </div>
                                                        <img src={e.caminho} alt="" />
                                                    </div>
                                                ))
                                            }
                                            {
                                                ft.map((e, index) => (
                                                    <div className="fts">
                                                        <div className="ftitem">
                                                            <i className="fas fa-times" onClick={a => this.setState({ avisoFT: true, indexft: index })}></i>
                                                            <i className="fas fa-edit" onClick={a => this.fiximagesize(index)}></i>
                                                        </div>
                                                        <img src={e} alt="" />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="desc-item-desc">
                                            <label>Observações</label>
                                            <textarea value={descricao} placeholder="Escreva aqui..." onChange={e => { this.setState({ descricao: e.target.value }) }} required></textarea>
                                        </div>
                                    </form>
                                </div>
                            </section>
                            <footer>

                                {
                                    edit === false &&
                                    <>
                                        <button  ref={btnNext => {
                                            this.btnNext = btnNext;
                                        }} id="btndoidprev" onClick={r => this.sendPergunta(-1)}><i className="fas fa-arrow-left"></i>&nbsp;Anterior</button>
                                        <button  ref={btnPrevs => {
                                            this.btnPrevs = btnPrevs;
                                        }} id="btndoidnext" onClick={r => this.sendPergunta(1)}>Próximo&nbsp;<i className="fas fa-arrow-right"></i></button>
                                    </>
                                }
                                {
                                    edit === true &&
                                    <>
                                        <button  ref={btnNext => {
                                            this.btnNext = btnNext;
                                        }} id="btndoidprev" onClick={r => this.updateAsk(-1)}><i className="fas fa-arrow-left"></i>&nbsp;Anterior</button>
                                        <button  ref={btnPrevs => {
                                            this.btnPrevs = btnPrevs;
                                        }} id="btndoidnext" onClick={r => this.updateAsk(1)}>Próximo&nbsp;<i className="fas fa-arrow-right"></i></button>
                                    </>
                                }
                                {/* {
                                    this.state.sended ? (
                                        <>
                                        </>
                                    ) : (
                                        <>
                                            <button><i className="fas fa-arrow-left"></i>&nbsp;Anterior</button>
                                            <button>Próximo&nbsp;<i className="fas fa-arrow-right"></i></button>
                                        </>
                                    )
                                } */}

                                {/* <button onClick={this.sendPergunta}>Salvar</button> */}
                            </footer>
                        </div>
                    }

                    {
                        special === true && done === false &&
                        <div className="perguntasVistorias App paddingB">
                            <header>
                                <a href="#" onClick={r => this.seeInit()}>
                                    <i className="fas fa-home"></i>
                                </a>
                                <img src="/Auth/logo.png" alt="" />
                            </header>
                            <section id="com-desc">
                                <div className="com-desc-head">
                                    <strong>{comodosHouse[selectedComodo].nome}</strong>
                                    <div className="item-select">
                                        <p>Outro</p>
                                    </div>
                                </div>
                                <div className="com-desc-itens">
                                    <form>
                                        <p className="mb-0">Descrição</p>
                                        <div className="desc-item">
                                            <input value={specialName} type="text" placeholder="Descrição do item" onChange={e => { this.setState({ specialName: e.target.value }) }} />
                                        </div>
                                        
                                        <p className="mb-0">Estado</p>
                                        <div className="desc-item mt-2">
                                            <select value={this.state.estadoespecial} onChange={e => { this.setState({ estadoespecial: e.target.value }) }}>
                                                <option value="-1">Selecione</option>
                                                <option value="Excelente">Excelente</option>
                                                <option value="Bom">Bom</option>
                                                <option value="Regular">Regular</option>
                                                <option value="Ruim">Ruim</option>
                                                <option value="Péssimo">Péssimo</option>
                                            </select>
                                        </div>

                                        <div>
                                            <p className="mb-0">Materiais</p>
                                            <div className="btn-itens" onClick={() => this.setState({ caractsModal: true, typecaracs: 2 })}>
                                                <i className="fas fa-layer-group"></i>
                                                <p>Materiais</p>
                                            </div>
                                            <p>
                                                {
                                                    this.state.addedcaract.filter(y => y.tipo_caracteristicas_id == 2).map(y => y.descricao).join(", ")
                                                }
                                            </p>
                                        </div>

                                        <div className="btn-itens-div">
                                            <p className="mb-0">Cores</p>
                                            <div className="btn-itens" onClick={() => this.setState({ caractsModal: true, typecaracs: 1 })}>
                                                <i className="fas fa-palette"></i>
                                                <p>Cores</p>
                                            </div>
                                            <p>
                                                {
                                                    this.state.addedcaract.filter(y => y.tipo_caracteristicas_id == 1).map(y => y.descricao).join(", ")
                                                }
                                            </p>
                                        </div>

                                        <p className="mb-0">Foto</p>
                                        <div className="desc-item-cam">
                                            <Input hidden className="btn-cam" multiple id="files" accept="image/*" type="file" onChange={(e) => this.addImage(e)} />
                                            <label htmlFor="files" className="btn-cam">
                                                Inserir fotos
                                            <i className="fas fa-camera"></i>
                                            </label>
                                        </div>
                                        <div id="list">
                                            {
                                                ft.map((e, index) => (
                                                    <div className="fts">
                                                        <div className="ftitem">
                                                            <i className="fas fa-times" onClick={a => this.setState({ avisoFT: true, indexft: index })}></i>
                                                            <i className="fas fa-edit" onClick={a => this.fiximagesize(index)}></i>
                                                        </div>
                                                        <img src={e} alt="" />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="desc-item-desc">
                                            <label>Observações</label>
                                            <textarea value={descricao} placeholder="Escreva aqui..." onChange={e => { this.setState({ descricao: e.target.value }) }} required></textarea>
                                        </div>
                                    </form>
                                </div>
                            </section>
                            <footer>
                                <button onClick={this.seeComodos}>Voltar</button>
                                {
                                    edit === false &&
                                    <button onClick={this.sendSpecialAsk}>Salvar</button>
                                }
                                {
                                    edit === true &&
                                    <button onClick={this.updateAsk}>Editar</button>
                                }
                            </footer>
                        </div>
                    }

                    {
                        final === true && done === true &&
                        <div className="final">
                            <p>Vistoria concluida</p>
                        </div>
                    }

                    {
                        vistoria.status === "Aberto" && done === true &&
                        <div className="final">
                            <p>Vistoria recusada</p>
                        </div>
                    }

                    {
                        done === "error" &&
                        <div className="final">
                            <p>Vistoria inválida</p>
                        </div>
                    }

                    {
                        aviso === true &&
                        <div className="aviso">
                            <div className="div-aviso">
                                <div className="text-aviso">
                                    <p>{avisomsg}</p>
                                    <button onClick={this.toggleAviso}>OK</button>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        avisoFT === true &&
                        <div className="aviso">
                            <div className="div-aviso">
                                <div className="text-aviso">
                                    <p>Deseja realmete excluir esta imagem?</p>
                                    <div className="flex-n">
                                        <button onClick={e => this.setState({ avisoFT: false, indexft: 0 })}>NÃO</button>
                                        <button onClick={e => this.rmimage(this.state.indexft)}>SIM</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        avisoME === true &&
                        <div className="aviso">
                            <div className="div-aviso">
                                <div className="text-aviso">
                                    <p>Deseja realmete excluir esta imagem?</p>
                                    <div className="flex-n">
                                        <button onClick={e => this.setState({ avisoME: false, indexft: 0 })}>NÃO</button>
                                        <button onClick={e => this.rmimageME(this.state.indexft)}>SIM</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        paintmaker == true &&
                        <div className="paintMaker" onClick={e => this.closefix(e)}>
                            <canvas id="nkonvas"></canvas>
                            <div className="smenucanvas">
                                <ul>
                                    {
                                        paintedLines == false ? (
                                            <li onClick={e => this.setState({ paintedLines: !paintedLines })}>
                                                <i className="fas fa-pen"></i> Desenhar
                                            </li>
                                        ) : (
                                                <>
                                                    <li onClick={e => this.setState({ paintedLines: !paintedLines })}>
                                                        <i className="fas fa-times"></i>&nbsp;Parar
                                                    </li>

                                                    <li>
                                                        <input type="color" name="" id="" value={basecolorstroke} onChange={a => this.setState({ basecolorstroke: a.target.value })} />
                                                    </li>

                                                    <li onClick={this.undo}>
                                                        <i className="fas fa-undo"></i>
                                                    </li>

                                                    <li onClick={this.redo}>
                                                        <i className="fas fa-redo"></i>
                                                    </li>

                                                </>
                                            )
                                    }
                                    <li className="saves" onClick={this.saveimage}>
                                        <i className="fas fa-check" ></i> Salvar
                            </li>
                                </ul>
                            </div>
                        </div>
                    }

                    {
                        caractsModal == true &&
                        <div className="paintMaker" onClick={e => {this.closefix(e); this.setState({seaCarac: ''})}}>
                            <div className="lambdaCaracs">
                                <header>
                                    <p>{tiposCaract[parseInt(this.state.typecaracs) - 1].descricao}</p>
                                </header>

                                <div className="carac-input">
                                    <input onChange={a => this.setState({seaCarac: a.target.value})} value={this.state.seaCarac} placeholder={`Procure por um ${tiposCaract[parseInt(this.state.typecaracs) - 1].descricao.toLowerCase()}`}/>
                                </div>

                                <label className="caracslabel" onClick={s => {
                                    this.closefix(null)
                                    this.setState({addedcaract: []})
                                }}>
                                    <div className="caracsstyle">Não se aplica</div>
                                </label>

                                <div className="complex">
                                    {
                                        caracList.filter(u => u.tipo_caracteristicas_id == this.state.typecaracs && (!this.state.seaCarac || (u.descricao.toLowerCase().includes(this.state.seaCarac.toLowerCase())))).map(u => (
                                            <label className="caracslabel">
                                                {
                                                    addedcaract.filter(o => o.descricao == u.descricao).length > 0 || hostcaract.filter(o => o.descricao == u.descricao).length > 0 ? (
                                                        <input checked type="checkbox" hidden onChange={g => this.addcaract(g, u)} />
                                                    ) : (
                                                        <input type="checkbox" hidden onChange={g => this.addcaract(g, u)} />
                                                    )
                                                }
                                                <div className="caracsstyle">{u.descricao}</div>

                                            </label>
                                        ))
                                    }
                                </div>

                                <div className="footer">
                                    <button onClick={e => {this.closefix(null); this.setState({seaCarac: ''})}}>Salvar</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </>

        );
    }
}

export default Form;